import slugify from "react-slugify";
import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// Get Product
export function getProducts(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/product/get-all", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_PRODUCT",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get All Product
export function getAllProduct(values) {
  return async function (dispatch) {

    ApiExt.post("/api/app/getAll-products", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_ALL_PRODUCT",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Single Product
export function getSingleProduct(_id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/product/get", { pid: _id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SINGLE_PRODUCT",
            payload: response.data.data,
          });
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Create Product
export function saveProduct(
  values,
  option,
  variant,
  seo,
  media,
  vendor,
  proType,
  callback,
  setLoading
) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      title: values.title,
      desc: values?.desc,
      ptype: {
        id: proType._id || "",
        name: proType.title || ""
      },
      vendor: {
        id: vendor._id || "",
        name: vendor.name || ""
      },
      collections: values.collections?.map((item) => item._id),
      images: media?.map((item) => item._id) || [],
      tags: values.tags,
      price: values.price,
      comPrice: values.comPrice,
      cost: values.cost,
      margin: ((values?.price - values?.cost) * 100) / values?.price || 0,
      profit: values?.price - values?.cost || 0,
      minQty: values.minQty,
      maxQty: values.maxQty,
      sku: values.sku,
      barcode: values.barcode,
      charge_tax: values.charge_tax,
      isTrackQty: values.isTrackQty,
      sellAtNill: values.sellAtNill,
      physical: values.physical,
      weight: {
        value: values.weight.value,
        unit: values.weight.unit,
      },
      option: option?.map((item) => {
        return {
          opName: item.opName,
          opVal: item.opVal,
        };
      }),
      taxid: values?.taxid,
      origin: values?.origin?._id,
      hs: values.hs,
      seo: {
        title: seo?.title || values?.title,
        desc: seo?.desc?.replace(/<[^>]+>/g, '') || values?.desc?.replace(/<[^>]+>/g, ''),
        slug: slugify(seo?.slug || values?.title),
      },
      status: values.status,
      variants: variant?.map((item) => {
        return {
          isdefault: item?.isdefault,
          option: item?.option,
          title: item?.title,
          desc: item?.desc || values?.desc,
          images: item?.images?.map((img) => img._id) || [],
          price: +item?.price || values?.price,
          comPrice: +item?.comPrice || values?.comPrice,
          cost: item?.vcost || values?.cost,
          margin:
            ((item?.price - item?.cost) * 100) / item?.price ||
            ((values?.price - values?.cost) * 100) / values?.price,
          profit: item?.price - item?.cost || values?.price - values?.cost,
          sku: item?.sku || values?.sku,
          barcode: item?.barcode || values?.barcode,
          isTrackQty: item?.isTrackQty || values?.isTrackQty,
          sellAtNill: item?.sellAtNill || values?.sellAtNill,
          physical: item?.physical || values?.physical,
          weight: {
            value: item?.weight?.value || values?.weight?.value,
            unit: item?.weight?.unit || values?.weight?.unit,
          },
          charge_tax: item?.charge_tax ? item?.charge_tax : false,
          taxid: item?.taxid || values?.taxid,
          origin: item?.origin || values?.origin?._id,
          status: item?.status,
        };
      }),
    };

    ApiExt.post("/api/product/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          toast.success(response?.data?.msg);
          callback(response.data?.id);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Product
export function updateProduct(
  id,
  values,
  option,
  variant,
  seo,
  media,
  category,
  vendor,
  proType,
  setLoading,
  callback
) {
  return async function (dispatch) {
    setLoading(true);

    if (category == "Gift Card") {
      var data = {
        pid: id,
        data: {
          title: values?.title,
          desc: values?.desc,
          ptype: {
            id: proType._id || "",
            name: proType.title || ""
          },
          vendor: {
            id: vendor?._id || "",
            name: vendor?.name || ""
          },
          collections: values?.collections?.map((item) => item._id),
          images: media?.map((item) => item._id) || [],
          tags: values?.tags,
          category: values?.category,
          option: option?.map((item) => {
            return {
              opName: item.opName,
              opVal: item.opVal,
            };
          }),
          seo: {
            title: seo?.title || values?.title,
            desc: seo?.desc || values?.desc,
            slug: slugify(seo?.slug || values?.title),
          },
          status: values?.status,

          variants: variant?.map((item) => {
            if (item._id) {
              return {
                _id: item?._id,
                isdefault: item?.isdefault,
                option: item?.option,
                title: item?.title,
                desc: item?.desc || values?.desc,
                images: item?.images?.map((img) => img._id) || [],
                price: +item?.price || +values?.price,
                comPrice: +item?.comPrice || +values?.comPrice,
                cost: +item?.cost || +values?.cost,
                margin:
                  ((+item?.price - +item?.cost) * 100) / +item?.price ||
                  ((+values?.price - +values?.cost) * 100) / +values?.price,
                profit:
                  +item?.price - +item?.cost || +values?.price - +values?.cost,
                sku: item?.sku || values?.sku,
                barcode: item?.barcode || values?.barcode,
                isTrackQty: item?.isTrackQty || values?.isTrackQty,
                sellAtNill: item?.sellAtNill || values?.sellAtNill,
                physical: item?.physical || values?.physical,
                weight: {
                  value: item?.weight?.value || values?.weight?.value,
                  unit: item?.weight?.unit || values?.weight?.unit,
                },
                taxid: item?.taxid || values?.taxid,
                origin: item?.origin?._id || values?.origin?._id,
                status: item?.status,
              };
            } else {
              return {
                isdefault: item?.isdefault,
                option: item?.option,
                title: item?.title,
                desc: item?.desc || values?.desc,
                images: values?.images?.map((img) => img._id) || [],
                price: item?.price || values?.price,
                comPrice: item?.comPrice || values?.comPrice,
                cost: item?.vcost || values?.cost,
                margin:
                  ((item?.price - item?.cost) * 100) / item?.price ||
                  ((values?.price - values?.cost) * 100) / values?.price,
                profit:
                  item?.price - item?.cost || values?.price - values?.cost,
                sku: item?.sku || values?.sku,
                barcode: item?.barcode || values?.barcode,
                isTrackQty: item?.isTrackQty || values?.isTrackQty,
                sellAtNill: item?.sellAtNill || values?.sellAtNill,
                physical: item?.physical || values?.physical,
                weight: {
                  value: item?.weight?.value || values?.weight?.value,
                  unit: item?.weight?.unit || values?.weight?.unit,
                },
                taxid: item?.taxid || values?.taxid,
                origin: item?.origin?._id || values?.origin?._id,
                status: item?.status,
              };
            }
          }),
        },
      };
    } else {
      data = {
        pid: id,
        data: {
          title: values?.title,
          desc: values?.desc,
          ptype: {
            id: proType?._id || "",
            name: proType?.title || ""
          },
          vendor: {
            id: vendor?._id || "",
            name: vendor?.name || ""
          },
          collections: values?.collections?.map((item) => item?._id),
          images: media?.map((item) => item?._id) || [],
          tags: values?.tags,
          price: values?.price,
          comPrice: values?.comPrice,
          cost: values.cost || 0,
          margin: ((values?.price - values?.cost) * 100) / values?.price || 0,
          profit: values?.price - values?.cost || 0,
          minQty: values.minQty,
      maxQty: values.maxQty,
          sku: values?.sku,
          barcode: values?.barcode,
          charge_tax: values.charge_tax,
          isTrackQty: values?.isTrackQty || false,
          sellAtNill: values?.sellAtNill || false,
          physical: values?.physical || false,
          weight: {
            value: values?.weight?.value,
            unit: values?.weight?.unit,
          },
          option: option?.map((item) => {
            return {
              opName: item.opName,
              opVal: item.opVal,
            };
          }),
          taxid: values?.taxid?._id || "",
          origin: values?.origin._id || "",
          hs: values?.hs,
          seo: {
            title: seo?.title || values?.title,
            desc: seo?.desc?.replace(/<[^>]+>/g, '') || values?.desc?.replace(/<[^>]+>/g, ''),
            slug: slugify(seo?.slug || values?.title),
          },
          status: values?.status,

          variants: variant?.map((item) => {
            if (item._id) {
              return {
                _id: item?._id,
                isdefault: item?.isdefault,
                option: item?.option,
                title: item?.title,
                desc: item?.desc?.replace(/<[^>]+>/g, '') || values?.desc?.replace(/<[^>]+>/g, ''),
                images: item?.images?.map((img) => img._id) || [],
                price: +item?.price || +values?.price,
                comPrice: +item?.comPrice || +values?.comPrice,
                cost: +item?.cost || +values?.cost,
                margin:
                  ((+item?.price - +item?.cost) * 100) / +item?.price ||
                  ((+values?.price - +values?.cost) * 100) / +values?.price,
                profit:
                  +item?.price - +item?.cost || +values?.price - +values?.cost,
                sku: item?.sku || values?.sku,
                barcode: item?.barcode || values?.barcode,
                isTrackQty: item?.isTrackQty || values?.isTrackQty,
                sellAtNill: item?.sellAtNill || values?.sellAtNill,
                physical: item?.physical || values?.physical,
                weight: {
                  value: item?.weight?.value || values?.weight?.value,
                  unit: item?.weight?.unit || values?.weight?.unit,
                },
                charge_tax: item?.charge_tax ? item?.charge_tax : false,
                taxid: item?.taxid || values?.taxid,
                origin: item?.origin?._id || values?.origin?._id,
                status: item?.status,
              };
            } else {
              return {
                isdefault: item?.isdefault,
                option: item?.option,
                title: item?.title,
                desc: item?.desc || values?.desc,
                images: values?.images?.map((img) => img._id) || [],
                price: item?.price || values?.price,
                comPrice: item?.comPrice || values?.comPrice,
                cost: item?.vcost || values?.cost,
                margin:
                  ((item?.price - item?.cost) * 100) / item?.price ||
                  ((values?.price - values?.cost) * 100) / values?.price,
                profit:
                  item?.price - item?.cost || values?.price - values?.cost,
                sku: item?.sku || values?.sku,
                barcode: item?.barcode || values?.barcode,
                isTrackQty: item?.isTrackQty || values?.isTrackQty,
                sellAtNill: item?.sellAtNill || values?.sellAtNill,
                physical: item?.physical || values?.physical,
                charge_tax: item?.charge_tax ? item?.charge_tax : false,
                weight: {
                  value: item?.weight?.value || values?.weight?.value,
                  unit: item?.weight?.unit || values?.weight?.unit,
                },
                taxid: item?.taxid || values?.taxid,
                origin: item?.origin?._id || values?.origin?._id,
                status: item?.status,
              };
            }
          }),
        },
      };
    }
    ApiExt.post("/api/product/update", data)
      .then(function (response) {
        setLoading(false);

        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          //  callback();
          dispatch({
            type: "UPDATE_PRODUCT",
            payload: data,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
}

// Archived Product
export function archiveProduct(id, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/product/archived", { pid: id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "ARCHIVED_PRODUCT",
            payload: id,
          });
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// unArchive Product
export function unarchiveProduct(id, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/product/unarchived", { pid: id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "UNARCHIVED_PRODUCT",
            payload: id,
          });
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Delete Product
export function deleteProduct(id, callback, deleteLoading) {
  return async function (dispatch) {
    deleteLoading(true);
    ApiExt.post("/api/product/delete", { pid: id })
      .then(function (response) {
        deleteLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "DELETE_PRODUCT",
            payload: id,
          });
          toast.success(response.data.msg);
          callback();
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        deleteLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Delete Product Multiple
export function deleteProductMultiple(_ids, lastId, setSelected) {
  return async function (dispatch) {
    ApiExt.post("api/product/delete-multiple", { pid: _ids })
      .then(function (response) {
        if (response.data.status === true) {
          setSelected([]);
          dispatch(
            getProducts({
              next: lastId,
              limit: 10,
              search: {},
            })
          );
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Product Type
export function getProductType() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-product-type", {
      next: "",
      limit: 1000,
    })
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "PRODUCT_TYPE",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
      });
  };
}

// Product Export
export function productExport(values, setloading, callback) {
  return async function (dispatch) {
    setloading(true);
    ApiExt.post("/api/product/export", values)
      .then(function (response) {
        setloading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback(response?.data?.data);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setloading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Product Import
export function productImport(values, existingProduct, setloading, callback) {
  return async function (dispatch) {
    setloading(true);
    const data = {
      ExistingProduct: existingProduct,
      products : values
    }
    ApiExt.post("/api/product/import", data)
      .then(function (response) {
        setloading(false);
        if (response.data.result.status === true) {
          callback(response?.data);
        } 
      })
      .catch(function (error) {
        setloading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}
