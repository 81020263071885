import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import {
    Alert,
    Stack,
    Select,
    Switch,
    Slider,
    TextField,
    FormControl,
    FormControlLabel,
    Divider,
    Box,
    Typography,
    Button,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem
} from "@material-ui/core";
import ImagePicker from "../../../components/_dashboard/media/ImagePicker";
import ProductPicker from '../../../components/_dashboard/products/ProductPicker'
import MenuPicker from './MenuPicker'
import CollectionPicker from './CollectionPicker'
import { makeStyles } from '@material-ui/core/styles';
import { QuillEditor } from "../../../components/editor";
// ----------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
ThemeEditor.propTypes = {
    value: PropTypes.object,
    getFieldProps: PropTypes.func,
    setFieldValue: PropTypes.func,
    fieldsValue: PropTypes.object,
    sx: PropTypes.object
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    defaultSpace: {
        marginBottom: 20
    }
});

export default function ThemeEditor({ value, getFieldProps, setFieldValue, fieldsValue, sx, ...other }) {

    const classes = useStyles();
    const editorSchemaData = useSelector(state => state.storepage.editorSchemaData);
    const [schema, setSchema] = useState({});

    useEffect(() => {
        if (value.cat == "editor_section") {
            setSchema(editorSchemaData[value.data.type]);
        }
        else if (value.cat == "editor_block") {
            setSchema(editorSchemaData[value.sectionType]?.blocks?.find(x => x.type == value.data.type));
        }
        else if (value.cat == "header_section") {
            setSchema(editorSchemaData[value.data.type]);
        }
        else if (value.cat == "header_block") {
            setSchema(editorSchemaData[value.sectionType]?.blocks?.find(x => x.type == value.data.type));
        }
        else if (value.cat == "footer_section") {
            setSchema(editorSchemaData[value.data.type]);
        }
        else if (value.cat == "footer_block") {
            setSchema(editorSchemaData[value.sectionType]?.blocks?.find(x => x.type == value.data.type));
        }
    }, []);

    return (
        <>
            {schema?.settings ? schema?.settings?.map((item) => {
                switch (item.type) {
                    case 'image_picker':
                        return <div key={item.id} className={classes.defaultSpace}>
                            <Typography variant="subtitle2" gutterBottom>
                                {item.label}
                            </Typography>
                            {item.info && <Typography variant="caption" gutterBottom>
                                {item.info}
                            </Typography>}

                            <ImagePicker
                                multiple={item.multiple}
                                history={item.history}
                                preview={item.preview}
                                onSelect={(selectedValue) =>
                                    setFieldValue(item.id, selectedValue)
                                }
                                //selectedValue = {value.data.settings[item.id]}
                                {...getFieldProps(item.id)}
                            ></ImagePicker>

                        </div>
                    case 'header':
                        return <div key={item.id} className={classes.defaultSpace}><h5>{item.content}</h5><Divider /></div>
                    case 'checkbox':
                        return <FormControlLabel key={item.id} className={classes.defaultSpace}
                            control={
                                <Switch
                                    onChange={(e) =>
                                        setFieldValue(item.id, e.target.checked)
                                    }
                                    defaultChecked={value.data.settings[item.id] || false}
                                    {...getFieldProps(item.id)}
                                />
                            }
                            label={item.label}
                            sx={{ mr: 2 }}
                        />
                    case 'text':
                        return <FormControl fullWidth>
                            <Typography variant="subtitle2" gutterBottom>
                                {item.label}
                            </Typography>
                            <TextField key={item.id} className={classes.defaultSpace}
                                defaultValue={value.data.settings[item.id] || item.default}
                                onChange={(selectedValue) =>
                                    setFieldValue(item.id, selectedValue)
                                }
                                {...getFieldProps(item.id)}></TextField></FormControl>
                    case 'multiline_text':
                        return <FormControl fullWidth>
                            <Typography variant="subtitle2" gutterBottom>
                                {item.label}
                            </Typography>
                            <TextField key={item.id} className={classes.defaultSpace} multiline
                                defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item?.default}
                                onChange={(selectedValue) =>
                                    setFieldValue(item.id, selectedValue)
                                }
                                {...getFieldProps(item.id)}></TextField></FormControl>
                    case 'inline_richtext':
                        return <FormControl fullWidth className={classes.defaultSpace}>
                            <Typography variant="subtitle2" gutterBottom>
                                {item.label}
                            </Typography>
                            <QuillEditor
                                key={item.id}
                                id={item.id}
                                defaultValue={value.data.settings[item.id] || item.default}
                                onChange={(selectedValue) =>
                                    setFieldValue(item.id, selectedValue)
                                }
                                value={fieldsValue[item.id]}
                            />
                        </FormControl>
                    case 'url':
                        return <FormControl fullWidth>
                            <Typography variant="subtitle2" gutterBottom>
                                {item.label}
                            </Typography>
                            <TextField key={item.id} className={classes.defaultSpace}
                                defaultValue={value.data.settings[item.id]}
                                {...getFieldProps(item.id)}></TextField>
                        </FormControl>
                    case 'product':
                        return <>
                            <Typography variant="subtitle2" gutterBottom>
                                {item.label}
                            </Typography>
                            <ProductPicker key={item.id} className={classes.defaultSpace}
                                //defaultValue={value.data.settings[item.id]}
                                onSelect={(selectedValue) =>
                                    setFieldValue(item.id, selectedValue)
                                }
                                multiple={false}
                                value={value.data.settings[item.id] || value.data.settings[item.id]?.default}
                            ></ProductPicker>
                        </>
                    case 'select':
                        return <>
                            <FormControl fullWidth>
                                {/* <InputLabel id={item.id}>{item.label}</InputLabel> */}
                                <Typography variant="subtitle2" gutterBottom>
                                    {item.label}
                                </Typography>
                                <Select labelId={item.id} key={item.id} className={classes.defaultSpace}
                                    defaultValue={value.data.settings[item.id]}
                                    onChange={(event) => {
                                        setFieldValue(item.id, event.target.value)
                                    }}
                                >
                                    {item.options.map((i) => {
                                        return <MenuItem value={i.value}>{i.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </>
                    case 'range':
                        return <>
                            <FormControl fullWidth className={classes.defaultSpace}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {item.label}
                                </Typography>
                                <Slider aria-label="Temperature"
                                    defaultValue={item.default}
                                    //getAriaValueText={valuetext}
                                    valueLabelDisplay="auto"
                                    step={item.step}
                                    marks
                                    min={item.min}
                                    max={item.max}
                                    onChange={(event) => {
                                        setFieldValue(item.id, event.target.value)
                                    }}
                                    {...getFieldProps(item.id)}
                                />
                            </FormControl>
                        </>
                    case 'menu':
                        return <>
                            <FormControl fullWidth className={classes.defaultSpace}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {item.label}
                                </Typography>
                                <MenuPicker
                                    onChange={(event) => {
                                        setFieldValue(item.id, event.target.value)
                                    }}
                                    defaultValue={value.data.settings[item.id]}

                                ></MenuPicker>
                            </FormControl>
                        </>
                    case 'collection':
                        return <>
                            <FormControl fullWidth className={classes.defaultSpace}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {item.label}
                                </Typography>
                                <CollectionPicker
                                    onChange={(event) => {
                                        setFieldValue(item.id, event.target.value)
                                    }}
                                    defaultValue={value.data.settings[item.id]}

                                ></CollectionPicker>
                            </FormControl>
                        </>
                    case 'logo':
                        return <>
                            <FormControl fullWidth className={classes.defaultSpace}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {item.label}
                                </Typography>
                                <small>
                                    {item.info}
                                </small>
                                {item.link &&
                                    <Link to={item.link} style={{ fontSize: '13px' }}>Go to Section</Link>
                                }
                            </FormControl>
                        </>
                }
            }) :
                <Alert severity="error">No Section configuration found or issue occured. Please check section schema from code editor.</Alert>
            }
        </>
    );
}