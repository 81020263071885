import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import {
    Alert,
    Stack,
    Select,
    Switch,
    Slider,
    TextField,
    FormControl,
    FormControlLabel,
    Divider,
    Box,
    Typography,
    Button,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@material-ui/core";
import ImagePicker from "../../../components/_dashboard/media/ImagePicker";
import ProductPicker from '../../../components/_dashboard/products/ProductPicker';
import MenuPicker from '../theme/MenuPicker';
import CollectionPicker from '../theme/CollectionPicker';
import { QuillEditor } from "../../../components/editor";
import ColorPicker from '../theme/ColorPicker';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// ----------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '100%',
        flexShrink: 0,
        fontWeight: 'bold'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


SettingsEditor.propTypes = {
    value: PropTypes.object,
    schema: PropTypes.object,
    getFieldProps: PropTypes.func,
    setFieldValue: PropTypes.func,
    fieldsValue: PropTypes.object,
    sx: PropTypes.object
};

// const useStyles = makeStyles({
//     root: {
//         flexGrow: 1
//     },
//     defaultSpace: {
//         marginBottom: 20
//     }
// });

export default function SettingsEditor({ value, getFieldProps, setFieldValue, fieldsValue, sx, ...other }) {

    const classes = useStyles();
    const editorSchemaData = useSelector(state => state.storepage.editorSchemaData);
    //const [schema, setSchema] = useState({});
    const [colorValue, setColorValue] = React.useState('#ffffff')
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className={classes.root}>

                {value.map((schemaItem) => {
                    if (schemaItem.settings) {
                        return (
                            <Accordion expanded={expanded === schemaItem?.name} onChange={handleChange(schemaItem?.name)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{ justifyContent: 'left' }}
                                >
                                    <Typography className={classes.heading}>{schemaItem?.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {schemaItem?.settings.map((item) => {
                                        switch (item?.type) {
                                            case 'image_picker':
                                                return <div key={item.id} className={classes.defaultSpace}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {item.label}
                                                    </Typography>
                                                    {item.info && <Typography variant="caption" gutterBottom>
                                                        {item.info}
                                                    </Typography>}

                                                    <ImagePicker
                                                        multiple={item.multiple}
                                                        history={item.history}
                                                        preview={item.preview}
                                                        onSelect={(selectedValue) =>
                                                            setFieldValue(item.id, selectedValue)
                                                        }
                                                        //selectedValue = {value.data.settings[item.id]}
                                                        {...getFieldProps(item.id)}
                                                    ></ImagePicker>

                                                </div>
                                            case 'header':
                                                return <div key={item.id} className={classes.defaultSpace}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item.content}
                                                    </Typography>
                                                    <Divider />
                                                </div>
                                            case 'checkbox':
                                                return <FormControlLabel key={item.id} className={classes.defaultSpace}
                                                    control={
                                                        <Switch
                                                            onChange={(e) =>
                                                                setFieldValue(item.id, e.target.checked)
                                                            }
                                                            defaultChecked={value.hasOwnProperty(item.id) ? value[item.id] : false}
                                                            {...getFieldProps(item.id)}
                                                        />
                                                    }
                                                    label={item.label}
                                                    sx={{ mr: 2 }}
                                                />
                                            case 'text':
                                                return <FormControl fullWidth>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {item.label}
                                                    </Typography>
                                                    <TextField key={item.id} className={classes.defaultSpace}
                                                        defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item?.default}
                                                        onChange={(selectedValue) =>
                                                            setFieldValue(item.id, selectedValue)
                                                        }
                                                        {...getFieldProps(item.id)}></TextField></FormControl>
                                            case 'multiline_text':
                                                return <FormControl fullWidth>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {item.label}
                                                    </Typography>
                                                    <TextField key={item.id} className={classes.defaultSpace} multiline
                                                        defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item?.default}
                                                        onChange={(selectedValue) =>
                                                            setFieldValue(item.id, selectedValue)
                                                        }
                                                        {...getFieldProps(item.id)}></TextField></FormControl>
                                            case 'inline_richtext':
                                                return <FormControl fullWidth className={classes.defaultSpace}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {item.label}
                                                    </Typography>
                                                    <QuillEditor
                                                        key={item.id}
                                                        id={item.id}
                                                        defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item.default}
                                                        onChange={(selectedValue) =>
                                                            setFieldValue(item.id, selectedValue)
                                                        }
                                                        value={fieldsValue[item.id]}
                                                    />
                                                </FormControl>
                                            case 'url':
                                                return <FormControl fullWidth>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {item.label}
                                                    </Typography>
                                                    <TextField key={item.id} className={classes.defaultSpace}
                                                        defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item.default}
                                                        {...getFieldProps(item.id)}></TextField>
                                                </FormControl>
                                            case 'product':
                                                return <>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {item.label}
                                                    </Typography>
                                                    <ProductPicker key={item.id} className={classes.defaultSpace}
                                                        //defaultValue={value.data.settings[item.id]}
                                                        onSelect={(selectedValue) =>
                                                            setFieldValue(item.id, selectedValue)
                                                        }
                                                        multiple={false}
                                                        value={value.hasOwnProperty(item.id) ? value[item.id] : item?.default}
                                                    ></ProductPicker>
                                                </>
                                            case 'select':
                                                return <>
                                                    <FormControl fullWidth>
                                                        {/* <InputLabel id={item.id}>{item.label}</InputLabel> */}
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {item.label}
                                                        </Typography>
                                                        <Select labelId={item.id} key={item.id} className={classes.defaultSpace}
                                                            defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item.default}
                                                            onChange={(event) => {
                                                                setFieldValue(item.id, event.target.value)
                                                            }}
                                                        >
                                                            {item.options.map((i) => {
                                                                return <MenuItem value={i.value}>{i.label}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            case 'range':
                                                return <>
                                                    <FormControl fullWidth className={classes.defaultSpace}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {item.label}
                                                        </Typography>
                                                        <Slider aria-label="Temperature"
                                                            defaultValue={item.default}
                                                            //getAriaValueText={valuetext}
                                                            valueLabelDisplay="auto"
                                                            step={item.step}
                                                            marks
                                                            min={item.min}
                                                            max={item.max}
                                                            onChange={(event) => {
                                                                setFieldValue(item.id, event.target.value)
                                                            }}
                                                            {...getFieldProps(item.id)}
                                                        />
                                                    </FormControl>
                                                </>
                                            case 'menu':
                                                return <>
                                                    <FormControl fullWidth className={classes.defaultSpace}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {item.label}
                                                        </Typography>
                                                        <MenuPicker
                                                            onChange={(event) => {
                                                                setFieldValue(item.id, event.target.value)
                                                            }}
                                                            defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item?.default}

                                                        ></MenuPicker>
                                                    </FormControl>
                                                </>
                                            case 'collection':
                                                return <>
                                                    <FormControl fullWidth className={classes.defaultSpace}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {item.label}
                                                        </Typography>
                                                        <CollectionPicker
                                                            onChange={(event) => {
                                                                setFieldValue(item.id, event.target.value)
                                                            }}
                                                            defaultValue={value.hasOwnProperty(item.id) ? value[item.id] : item?.default}

                                                        ></CollectionPicker>
                                                    </FormControl>
                                                </>
                                            case 'logo':
                                                return <>
                                                    <FormControl fullWidth className={classes.defaultSpace}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {item.label}
                                                        </Typography>
                                                        <small>
                                                            {item.info}
                                                        </small>
                                                        {item.link &&
                                                            <Link to={item.link} style={{ fontSize: '13px' }}>Go to Section</Link>
                                                        }
                                                    </FormControl>
                                                </>
                                            case 'color':
                                                return <>
                                                    <FormControl fullWidth className={classes.defaultSpace}>

                                                        <ColorPicker
                                                            label={item.label}
                                                            onChange={(color) => {setFieldValue(item.id, color.hex) }}
                                                            defaultValue={item?.default}
                                                            value={fieldsValue[item?.id]}
                                                        />
                                                        <small>
                                                            {item.info}
                                                        </small>
                                                    </FormControl>
                                                </>
                                        }
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                })}

            </div>
        </>
    );
}