import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// Get All Transfer
export function getAllTransfer(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/transfer/get-all", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_ALL_TRANSFER",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Single Transfer
export function getSingleTransfer(_id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/transfer/get", { transferId: _id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SINGLE_TRANSFER",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Insert Transfer
export function insertTransfer(values, items, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      origin: {
        id: values?.origin?._id,
        type: "sup",
      },
      destination: {
        id: values?.destination?._id,
        type: "loc",
      },
      items: items?.map((value) => {
        return {
          pid: value?.product?._id,
          qty: value?.qty,
          vid: value?.pid ? "" : value?._id,
        };
      }),
      arrival: values?.arrival,
      tracking: {
        number: values?.trackingNo || "",
        carrier: values?.shipCarrier || "",
        url: values?.url || "",
      },
      ref: values?.ref,
      tags: values?.tags,
    };
    ApiExt.post("/api/transfer/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success("Transfer Inserted Succesfully");
          callback(response.data.id);
        } else {
          toast.warn("Can't enter same email and phone number..");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Transfer
export function updateTransfer(tId, values, items, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      id: tId,
      origin: {
        id: values?.origin?._id,
        type: "sup",
      },
      destination: {
        id: values?.destination?._id,
        type: "loc",
      },
      items: items?.map((value) => {
        delete value.product;
        delete value.variant;
        return value;
      }),
      arrival: values?.arrival,
      tracking: {
        number: values?.trackingNo || "",
        carrier: values?.shipCarrier || "",
        url: values?.url || "",
      },
      ref: values?.ref,
      tags: values?.tags,
    };
    ApiExt.post("/api/transfer/update", data)
      .then(function (response) {

        setLoading(false);

        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_TRANSFER",
            payload: data,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
}

// Delete Transfer
export function DelTransfer(tId, setDeleteLoading, callback) {
  return async function (dispatch) {
    setDeleteLoading(true);
    ApiExt.post("/api/transfer/delete", { transferId: tId })
      .then(function (response) {
        setDeleteLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "DELETE_TRANSFER",
            payload: tId,
          });
          toast.success(response.data.msg);
          callback();
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setDeleteLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get All received Inventory
export function getAllReceivedInventory(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    const data = {
      transferId: id,
    };

    ApiExt.post("/api/receive_inventory/get", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_ALL_RECEIV_INVENTORY",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Transfer
export function updateReceivedInventory(id, items, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      id: id,
      items: items?.map((value) => {
        return {
          pid: value?.items?.pid,
          qty: value?.items?.qty,
          vid: value?.items?.vid,
          acptQty: value?.items?.acpt,
          rejctQty: value?.items?.rjct,
          product: value?.product?.title,
          variant: value?.variant?.title,
        };
      }),
    };

    ApiExt.post("/api/receive_inventory/update", data)
      .then(function (response) {
        setLoading(false);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_RECEIV_INVENTORY",
            payload: data,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
}
