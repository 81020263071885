import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";
import slugify from "react-slugify";

//Get All Navigation
export function getNavigations(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/nevigation/get-all", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_NAVIGATIONS",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Navigation
export function getNavigation(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/nevigation/get", { id: id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SINGLE_NAVIGATION",
            payload: response.data.data,
          });
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Create Navigation
export function insertNavigation(values, items,callback, setLoading ) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      title: values?.title,
      handle: values?.handle != "" ? slugify(values?.handle) : slugify(values?.title),
      items: items?.map((item) => {
        return {
          name: item.name,
          title: item.title,
          link: item.url
        }
      }),
    };
    ApiExt.post("/api/nevigation/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          toast.success(response?.data?.msg);
          callback(response?.data?.id);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Navigation
export function updateNavigation(id, values, items, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      id: id,
      title: values?.title,
      handle: values?.handle != "" ? slugify(values?.handle) : slugify(values?.title),
      items: items
    };


    ApiExt.post("/api/nevigation/update", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Delete Navigation
export function deleteNavigation(id, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/nevigation/delete", { id: id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response.data.msg);
          callback();
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}
