import { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import {
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Grid,
  AppBar,
  Tab,
  makeStyles,
  Tabs,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { getCollections } from "src/redux/actions";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  // justifyContent: 'space-around',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  // width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  setSelectedTab: PropTypes.func,
  setText: PropTypes.func,
};

export default function ListToolbar(props) {
  const { setSelectedTab, setText } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: "#FFF",
    },
    tabBar: {
      paddingLeft: 20,
      backgroundColor: "#FFF",
    },
    linearContainer: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  const [statusTab, setStatusTab] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleCollectionData = (props) => {
    dispatch(
      getCollections({
        next: props.next,
        prev: props.prev,
        limit: props.limit,
        search: props.search,
      })
    );
  };

  const handleTextSearch = _.debounce((event) => {
    setText(event);
    handleCollectionData({
      next: "",
      prev: "",
      limit: 10,
      search: {
        name: event,
        status: "",
      },
    });
  }, 500);

  const handleTabChange = (event, newValue) => {
    setStatusTab(newValue);
    setSelectedTab(newValue);
    handleCollectionData({
      next: "",
      prev: "",
      limit: 10,
      search: {
        name: searchText,
        status: newValue,
      },
    });
  };

  return (
    <>
      <TabContext value={statusTab}>
        <AppBar position="static" color="default">
          <Tabs onChange={handleTabChange} aria-label="simple tabs example"    variant="scrollable"
              scrollButtons="on">
            <Tab label="All" value="" />
            <Tab label="Active" value="active" />
            <Tab label="Draft" value="draft" />
            <Tab label="Archived" value="archive" />
          </Tabs>
        </AppBar>
      </TabContext>
      <RootStyle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchStyle
              fullWidth
              style={{height:45}}
              placeholder="Search collections..."
              value={searchText}
              onChange={(e) => (
                setSearchText(e.target.value), handleTextSearch(e.target.value)
              )}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
          </Grid>
          <>
            {/* <Grid item xs={3}>
              <Autocomplete
                {...collectionProps}
                value={collection}
                onChange={(event, newValue) => {
                  handleCollectionChange(newValue);
                }}
                disablePortal
                id="combo-box-demo"
                placeholder="Product type"
                renderInput={(params) => <TextField {...params} label="Collection" />}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                {...vendorProps}
                value={vendor}
                onChange={(event, newValue) => {
                  handleVendorChange(newValue);
                }}
                disablePortal
                id="combo-box-demo"
                renderInput={(params) => <TextField {...params} label="Vendors" />}
              />
            </Grid> */}
          </>
        </Grid>
      </RootStyle>
    </>
  );
}
