import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// Get Store Theme
export function getTheme() {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("api/store/get-theme")
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_THEME",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Store Theme Library
export function getThemeLibrary() {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("api/store/get-theme-library")
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_THEME_LIBRARY",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Store
export function getStore() {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/get-store")
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_STORE",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Store
export function updateStore(id, ownerId, values, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      storeId: id,
      data: {
        name: values?.name,
        owner: ownerId,
        details: {
          storeContact: values?.storeContact,
          senderEmail: values?.senderEmail,
          industry: values?.industry,
        },
        address: {
          legalName: values?.legalName,
          address: values?.address,
          apertment: values?.apertment,
          city: values?.city,
          country: values?.country?._id,
          province: values?.province?._id,
          pincode: values?.pincode,
          phone: values?.phone,
          ext: values?.ext?.dial_code || "",
        },
        format: {
          timeZone: values?.timeZone?._id || "",
          unit: values?.unit,
          weight: values?.weight,
          orderPre: values?.orderPre,
          orderSuf: values?.orderSuf,
          ordNo: +values?.ordNo,
          currency: values?.currency,
        },
      },
    };


    ApiExt.post("/api/store/update", data)
      .then(function (response) {
        setLoading(false);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success("Store updated");
          dispatch({
            type: "UPDATE_STORE",
            payload: data,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get State
export function getState() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-state")
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_STATE",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
      });
  };
}

// Get Currency
export function getCurrency() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-currency")
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_CURRENCY",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
      });
  };
}

// Get TimeZone
export function getTimeZone() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-timeZone")
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_TIMEZONE",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
      });
  };
}

export function getUnitSystem() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-unitSystem")
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_UNITSYSTEM",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
      });
  };
}

export function getIndustry() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-industry")
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_INDUSTRY",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
      });
  };
}

export function getWebsiteScreenShots(uri) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING_WEBSITE_SCREENSHOTS",
      payload: true,
    });

    ApiExt.post("api/app/screenshoots", { uri: uri })
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_WEBSITE_SCREENSHOTS",
            payload: response.data.data,
          });
          dispatch({
            type: "IS_LOADING_WEBSITE_SCREENSHOTS",
            payload: false,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
      });
  };
}

// GiftCard
export function updateGiftCard(values, setLoading,callback) {
  return async function (dispatch) {
    setLoading(true);
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    const value = {
      data: {
        giftCard: {
          type: values.type,
          value: values.type == "nvr" ? 1 : values?.value,
          exp_type: values.type == "nvr" ? "year" : values?.exp_type,
        },
      },
    };

    ApiExt.post("/api/settings/giftcard-update", value)
      .then(function (response) {

        setLoading(false);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_GIFTCARD",
            payload: value.data,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        // toast.error("Something went wrong. Please try again.");
      });
  };
}
