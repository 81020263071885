import {
  Container,
  Stack,
  Button,
  Typography,
  Grid,
  Card,
  Link,
  LinearProgress,
  linearProgressClasses,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  TextField,
  TableContainer,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Page from "src/components/Page";
import {
  getAllReceivedInventory,
  getSingleTransfer,
  updateReceivedInventory,
} from "src/redux/actions/TransferAction";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  withStyles,
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import { getAllProduct } from "src/redux/actions";
import { LoadingButton } from "@material-ui/lab";

const acceptPercentage = (total, value) => {
  return (value / total) * 100;
};

const rejectPercentage = (total, acptvalue, rejctvalue) => {
  return (acptvalue / total) * 100 + (rejctvalue / total) * 100;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function RecvInventory() {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const singleTransferData = useSelector(
    (state) => state.transfer.singleTransferPayload
  );

  const Inventory = useSelector(
    (state) => state.transfer.receiveInventoryPayload
  );
  const isLoading = useSelector((state) => state.loader.loadingPayload);

  const [rcvinventory, setRcvInventory] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getSingleTransfer(_id));
    dispatch(getAllReceivedInventory(_id));
  }, []);

  useEffect(() => {
    const items = Inventory?.map((item) => {
      item.items.acpt = 0;
      item.items.rjct = 0;
      item.items.total = (item.items.acptQty || 0) + (item.items.rejctQty || 0);
      return item;
    });
    setRcvInventory(items);
  }, [Inventory]);

  useEffect(() => {
    const filter = rcvinventory
      ?.filter((x) => x?.items?.acpt > 0 || x?.items?.rjct > 0)
      ?.map((value) => {
        return value;
      });
    setdata(filter);
  }, [rcvinventory]);

  const clickAccept = (data, item) => {
    let val = rcvinventory.map((op) => {
      if ((op.items.vid || op.items.pid) == (item.vid || item.pid)) {
        op.items.acpt = +data;

        return op;
      } else {
        return op;
      }
    });
    setRcvInventory(val);
  };

  const clickReject = (data, item) => {
    let val = rcvinventory?.map((op) => {
      if ((op.items.vid || op.items.pid) == (item.vid || item.pid)) {
        op.items.rjct = +data;

        return op;
      } else {
        return op;
      }
    });
    setRcvInventory(val);
  };

  const allAccept = () => {
    const val = rcvinventory
      ?.filter((op) => op.items.total < op.items.qty)
      ?.map((value) => {
        value.items.acpt = value.items.qty - value.items?.acptQty;
        value.items.rjct = 0;
        return value;
      });

    // let val = rcvinventory?.map((op) => {
    //   if (op.items.total < op.items.qty) {
    //     op.items.rjct = op.items.qty - op.items.rejctQty;
    //     op.items.acpt = 0;
    //     return op;
    //   } else {
    //     return op;
    //   }
    // });
    // setRcvInventory(val);
  };

  const allReject = () => {
    // let val = rcvinventory?.map((op) => {
    //   if (op.items.total < op.items.qty) {
    //     op.items.acpt = op.items.qty - op.items.acptQty;
    //     op.items.rjct = 0;
    //     return op;
    //   } else {
    //     return op;
    //   }
    // });
    // setRcvInventory(val);
  };

  return (
    <Page>
      <Container>
        {isLoading && <LinearProgress style={{ width: "100%" }} />}
        {!isLoading && (
          <>
            {/* heading */}
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                style={{
                  marginBottom: "16px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  minHeight: "42px",
                  minWidth: "10px",
                }}
                component={RouterLink}
                to={`${PATH_DASHBOARD.products.editTransfer}/${_id}`}
              >
                <img
                  width="17px"
                  src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
                />
              </Button>
              <Stack spacing={1}>
                <Typography variant="h5" style={{ marginTop: 5 }}>
                  Receive items
                </Typography>
                <Typography style={{ fontSize: "15px", color: "grey" }}>
                  #T{singleTransferData?.transferNo}
                </Typography>
              </Stack>
            </Stack>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3, mt: 1 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <h4>Products</h4>
                    {/* {rcvinventory?.filter((item)=> 
                        item?.items?.total !=
                          item?.items?.qty).length > 0 && (
                    <Stack direction={"row-reverse"} spacing={2}>
                      <Link  style={{cursor: "pointer"}} onClick={() => allReject()}>Reject all unreceived</Link>
                      <Link style={{cursor: "pointer"}} onClick={() => allAccept()} >Accept all unreceived</Link>
                    </Stack>
                    )} */}
                  </Stack>
                  <LinearProgress
                    style={{ marginTop: 20 }}
                    variant="buffer"
                    value={acceptPercentage(
                      singleTransferData?.qty,
                      singleTransferData?.acptQty || 0
                    )}
                    valueBuffer={rejectPercentage(
                      singleTransferData?.qty,
                      singleTransferData?.acptQty || 0,
                      singleTransferData?.rejctQty || 0
                    )}
                  />
                  <Typography
                    style={{ float: "right", fontSize: "13px", marginTop: 10 }}
                  >
                    Total received :{singleTransferData?.total} of{" "}
                    {singleTransferData?.qty}
                  </Typography>
                  <TableContainer component={Paper}>
                  <Table sx={{ mt: 7 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>SKU</TableCell>
                        {rcvinventory?.filter(
                          (item) => item?.items?.total != item?.items?.qty
                        ).length > 0 && (
                          <>
                            <TableCell>Accept</TableCell>
                            <TableCell>Reject</TableCell>
                          </>
                        )}

                        <TableCell>Received</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rcvinventory?.map((item) => {
                        if (item?.items?.total < item?.items?.qty) {
                          return (
                            <>
                              <TableRow>
                                {/* img title */}
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Avatar
                                      variant="square"
                                      alt={item?.product?.title}
                                      src={
                                        item?.variant?.images?.length > 0
                                          ? item?.product?.images?.[0]?.url
                                          : item?.product?.images?.length > 0
                                          ? item?.product?.images?.[0]?.url
                                          : "public/favicon/product.png"
                                      }
                                    />
                                    <Stack>
                                      <Typography
                                        style={{
                                          maxWidth: "130px",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          marginTop: 2,
                                        }}
                                      >
                                        {item?.product?.title}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: "13px",
                                          color: "grey",
                                        }}
                                      >
                                        {item?.variant?.title}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </TableCell>
                                {/* sku */}
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: "13px",
                                      color: "grey",
                                    }}
                                  >
                                    {item?.variant?.sku
                                      ? item?.variant?.sku
                                      : item?.product?.sku
                                      ? item?.product?.sku
                                      : "No SKU"}
                                    {/* {item?.product?.sku} */}
                                  </Typography>
                                </TableCell>
                                {/* accept */}
                                <TableCell>
                                  <TextField
                                  size="small"
                                    helperText={
                                      item?.items?.total +
                                        item?.items?.acpt +
                                        item?.items?.rjct >
                                        item?.items?.qty && (
                                        <Typography
                                          color="error"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Accpect + Reject is greaterthan Qty
                                        </Typography>
                                      )
                                    }
                                    onChange={(e) =>
                                      clickAccept(e.target.value, item.items)
                                    }
                                  />
                                </TableCell>
                                {/* reject */}
                                <TableCell>
                                  <TextField
                                  size="small"
                                    helperText={
                                      item?.items?.total +
                                        item?.items?.acpt +
                                        item?.items?.rjct >
                                        item?.items?.qty && (
                                        <Typography
                                          color="error"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Accpect + Reject is greaterthan Qty
                                        </Typography>
                                      )
                                    }
                                    onChange={(e) =>
                                      clickReject(e.target.value, item.items)
                                    }
                                  />
                                </TableCell>
                                {/* bar */}
                                <TableCell>
                                  <LinearProgress
                                    variant="buffer"
                                    value={acceptPercentage(
                                      item?.items?.qty,
                                      item?.items?.acptQty || 0
                                    )}
                                    valueBuffer={rejectPercentage(
                                      item?.items?.qty,
                                      item?.items?.acptQty || 0,
                                      item?.items?.rejctQty || 0
                                    )}
                                  />
                                  {/* <BorderLinearProgress
                                    sx={{ mt: 1 }}
                                    color="error"
                                    variant="determinate"
                                    value={item?.items?.rejctQty || 0}
                                  /> */}
                                  <Typography
                                    style={{ float: "right", fontSize: "13px" }}
                                  >
                                    {(item?.items?.acptQty || 0) +
                                      (item?.items?.rejctQty || 0)}{" "}
                                    of {item?.items?.qty}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }

                        if (singleTransferData?.status == "Received") {
                          return (
                            <>
                              <TableRow>
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Avatar
                                      variant="square"
                                      alt={item?.product?.title}
                                      src={
                                        item?.variant?.images?.length > 0
                                          ? item?.product?.images?.[0].url
                                          : item?.product?.images?.length > 0
                                          ? item?.product?.images?.[0].url
                                          : "public/favicon/product.png"
                                      }
                                    />
                                    <Stack>
                                      <Typography
                                        style={{
                                          maxWidth: "130px",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          marginTop: 2,
                                        }}
                                      >
                                        {item?.product?.title}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: "13px",
                                          color: "grey",
                                        }}
                                      >
                                        {item?.variant?.title}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: "13px",
                                      color: "grey",
                                    }}
                                  >
                                    {item?.variant?.sku
                                      ? item?.variant?.sku
                                      : item?.product?.sku
                                      ? item?.product?.sku
                                      : "No SKU"}
                                    {item?.product?.sku}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <LinearProgress
                                    variant="buffer"
                                    value={acceptPercentage(
                                      item?.items?.qty,
                                      item?.items?.acptQty || 0
                                    )}
                                    valueBuffer={rejectPercentage(
                                      item?.items?.qty,
                                      item?.items?.acptQty || 0,
                                      item?.items?.rejctQty || 0
                                    )}
                                  />
                                  <Typography
                                    style={{ float: "right", fontSize: "13px" }}
                                  >
                                    {item?.items?.acptQty +
                                      item?.items?.rejctQty}{" "}
                                    of {item?.items?.qty}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                {rcvinventory?.filter(
                  (item) => item?.items?.total != item?.items?.qty
                ).length > 0 && (
                  <LoadingButton
                    variant="contained"
                    style={{ float: "right", marginTop: 20 }}
                    disabled={
                      data?.length > 0
                        ? rcvinventory?.filter(
                            (x) =>
                              x?.items?.total +
                                x?.items?.acpt +
                                x?.items?.rjct >
                              x?.items?.qty
                          ).length > 0
                          ? true
                          : false
                        : true
                    }
                    loading={loading}
                    onClick={() =>
                      dispatch(
                        updateReceivedInventory(_id, data, setLoading, () =>
                          navigate(
                            `${PATH_DASHBOARD.products.editTransfer}/${_id}`
                          )
                        )
                      )
                    }
                  >
                    Save
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
}
