import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
// material
import {
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
  TextField,
  Grid,
  AppBar,
  Tab,
  makeStyles,
  Tabs,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { getProducts } from "src/redux/actions";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  // justifyContent: 'space-around',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  // width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {  
  checkout: PropTypes.object,
  setSelectedTab: PropTypes.func,
  setText: PropTypes.func,
  onSelect: PropTypes.func,
};

export default function ListToolbar(props) {
  const { checkout, setSelectedTab, setText, onSelect } = props;
  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: "#FFF",
    },
    tabBar: {
      paddingLeft: 20,
      backgroundColor: "#FFF",
    },
    linearContainer: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();
  const isLight = theme.palette.mode === "light";

  const dispatch = useDispatch();

  const collectionData = useSelector(
    (state) => state.collections.collectionPayload
  );
  const vendorData = useSelector((state) => state.vendors.vendorPayload);
  const collectionProps = {
    options: collectionData,
    getOptionLabel: (option) => option.title,
    value: (option) => option._id,
  };
  const vendorProps = {
    options: vendorData,
    getOptionLabel: (option) => option.name,
    value: (option) => option._id,
  };

  const [statusTab, setStatusTab] = useState("");
  const [searchText, setSearchText] = useState("");
  const [collection, setCollection] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagRow, setTagRow] = useState([]);
  const [tagInput, setTagInput] = useState("");

  const handleProductData = (props) => {
    onSelect({
      next: props.next,
      prev: props.prev,
      limit: props.limit,
      search: props.search,
    });
    dispatch(
      getProducts({
        next: props.next,
        prev: props.prev,
        limit: props.limit,
        search: props.search,
      })
    );
  };

  const handleTextSearch = _.debounce((event) => {
    setText(event);
    handleProductData({
      next: "",
      prev: "",
      limit: 10,
      search: {
        name: event,
        vendor: vendor ? vendor?._id : "",
        collection: collection ? collection?._id : "",
        tags: tags,
        status: statusTab,
      },
    });
  }, 500);

  const handleTabChange = (event, newValue) => {
    setStatusTab(newValue);
    setSelectedTab(newValue);
    handleProductData({
      next: "",
      prev: "",
      limit: 10,
      search: {
        name: searchText,
        vendor: vendor ? vendor?._id : "",
        // collection: collection ? collection?._id : "",
        // tags: tags,
        status: newValue,
      },
    });
  };

  const handleVendorChange = (event) => {
    setVendor(event);
    setText(event?._id);
    handleProductData({
      next: "",
      prev: "",
      limit: 10,
      search: {
        name: searchText,
        vendor: event?._id ? event?._id : "",
        // collection: collection ? collection?._id : "",
        // tags: tags,
        status: statusTab,
      },
    });
  };

  return (
    <>
      <TabContext value={statusTab}>
        <AppBar position="static" color="default">
          {checkout ? (
            <Tabs
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab label="All" value="" />
            </Tabs>
          ) : (
            <Tabs
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="on"
            >
              <Tab label="All" value="" />
              <Tab label="Active" value="active" />
              <Tab label="Draft" value="draft" />
              <Tab label="Archived" value="archive" />
            </Tabs>
          )}
        </AppBar>
      </TabContext>
      <RootStyle>
          <Grid container spacing={2}>
            <Grid Grid item xs={12} md={6}>
              <SearchStyle
                fullWidth
                style={{ height: "41px" }}
                placeholder="Product search..."
                value={searchText}
                onChange={(e) => (
                  setSearchText(e.target.value),
                  handleTextSearch(e.target.value)
                )}
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            {/* <Grid item xs={3}>
              <Autocomplete
                {...collectionProps}
                value={collection}
                onChange={(event, newValue) => {
                  handleCollectionChange(newValue);
                }}
                disablePortal
                id="combo-box-demo"
                placeholder="Product type"
                renderInput={(params) => (
                  <TextField {...params} label="Collection" />
                )}
              />
            </Grid> */}
            <Grid Grid item xs={12} md={6}>
              <Autocomplete
                {...vendorProps}
                value={vendor}
                onChange={(event, newValue) => {
                  handleVendorChange(newValue);
                }}
                disablePortal
                id="combo-box-demo"
                renderInput={(params) => (
                  <TextField {...params} label="Vendors" size="small"/>
                )}
              />
            </Grid>
            {/* <Grid item xs={3}>
              <Autocomplete
                multiple
                freeSolo
                id="tags-standard"
                options={[]}
                value={tags}
                inputValue={tagInput}
                onChange={(event, newValue) => {
                  setTags(newValue);
                }}
                onInputChange={(event, newInputValue) =>
                  handleTagInputChange(newInputValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    placeholder="Separate options with a comma"
                    onChange={(e) => handleTagChange(e)}
                  />
                )}
              />
            </Grid> */}
          </Grid>
      </RootStyle>
    </>
  );
}
