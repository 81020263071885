import { Alert, Button, Stack } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { PATH_DASHBOARD } from "src/routes/paths";

import moment from "moment";
import { Link as RouterLink } from "react-router-dom";


function getNumberOfDays(start, end) {
  const date1 = moment(start);
  const date2 = moment(end);

  // Calculating the no. of days between two dates
  const diffInDays = date2.diff(date1, 'days')

  return diffInDays;
}

export default function PlanAlert() {
  const store = useSelector((state) => state.auth.profilePayload);
  const newDate = new Date();
  const expiry = store?.plan?.exp?.end;
  const days = getNumberOfDays(new Date(), store?.plan?.exp?.end);
  return (
    <Stack sx={{ mb: 3 }}>
      {newDate > expiry ? (
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              component={RouterLink}
              to={PATH_DASHBOARD.setting.upgradePlan}
            >
              Go to Plan
            </Button>
          }
        >
          Your Plan is Expired!
        </Alert>
      ) :
        (days >= 1 && days <= 7) && (
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                component={RouterLink}
                to={PATH_DASHBOARD.setting.upgradePlan}
              >
                Go to Plan
              </Button>
            }
          >
            Your Plan is Expired within {days} days!
          </Alert>
        )}
    </Stack>
  );
}
