import React, { useState } from "react";
import PropTypes from "prop-types";
import { Stack } from "@material-ui/core";
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  timelineItemClasses,
} from "@material-ui/lab";
import { ArrowDropDown, FiberManualRecord } from "@material-ui/icons";
import CreateFulfillTimeline from "./CreateFulfillTimeline";
import CreateReturnTimeline from "./CreateReturnTimeline";
import CancelFulfillTimeline from "./CancelFulfillTimeline";
import RefundTimeline from "./RefundTimeline";
import OrderNoteTimeline from "./OrderNoteTimeline";
import UpdateCustomerTimeline from "./UpdateCustomerTimeline";
import RemoveCustomerTimeline from "./RemoveCustomerTimeline";
import CancelReturnTimeline from "./CancelReturnTimeline";
import { fTime } from "../../../../utils/formatTime";
import MarkPaidTimeline from "./MarkPaidTimeline";
import OrderPaymentTimeline from "./OrderPaymentTimeline";
import ReturnTrackingTimeline from "./ReturnTrackingTimeline";
import TimeLineComment from "../TimeLineComment";

OrderTimeline.propTypes = {
  order: PropTypes.object,
  check: PropTypes.bool,
};

export default function OrderTimeline(props) {
  const { order, check } = props;

  const [accordion, setAccordion] = useState(false);
  return (
    <>
      {order.msg ? (
        <>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <FiberManualRecord style={{ color: "green" }} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <>
                  <Stack direction={"row"} justifyContent="space-between">
                    <Stack
                      direction={"row"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setAccordion(!accordion)}
                    >
                      <p style={{ marginTop: -5, fontSize: "13px" }}>
                        {order.msg}
                        {order?.tlData?.loc && (
                          <>
                            {order?.tlData?.loc?.address},
                            {order?.tlData?.loc?.city},
                            {order?.tlData?.loc?.province},
                            {order?.tlData?.loc?.country}
                          </>
                        )}
                      </p>{" "}
                      {order?.tlData && (
                        <ArrowDropDown
                          style={{
                            cursor: "pointer",
                            marginTop: "-6px",
                            marginRight: "15px",
                          }}
                        />
                      )}
                    </Stack>

                    <p
                      style={{
                        fontSize: "14px",
                        color: "grey",
                        marginTop: -5,
                        whiteSpace: "nowrap", 
                      }}
                    >
                      {" "}
                      {fTime(order?.cdate)}
                    </p>
                  </Stack>

                  {accordion && (
                    <>
                      {order?.type == "createFulfill" && (
                        <CreateFulfillTimeline fulfill={order?.tlData} />
                      )}
                      {order?.type == "createReturn" && (
                        <CreateReturnTimeline Return={order?.tlData} />
                      )}
                      {order?.type == "cancelFulfill" && (
                        <CancelFulfillTimeline canFul={order?.tlData} />
                      )}
                      {order?.type == "refund" && (
                        <RefundTimeline refund={order?.tlData} />
                      )}
                      {order?.type == "updateNote" && (
                        <OrderNoteTimeline note={order?.tlData} />
                      )}
                      {order?.type == "updateCustomer" && (
                        <UpdateCustomerTimeline cust={order?.tlData} />
                      )}
                      {order?.type == "removedCustomer" && (
                        <RemoveCustomerTimeline cust={order?.tlData} />
                      )}
                      {order?.type == "markasPaid" && (
                        <MarkPaidTimeline paid={order?.tlData} />
                      )}
                      {order?.type == "cancelReturn" && (
                        <CancelReturnTimeline canRet={order?.tlData} />
                      )}
                      {order?.type == "processed payment method" && (
                        <OrderPaymentTimeline payment={order?.tlData} />
                      )}
                      {order?.type == "added payment terms" && (
                        <OrderPaymentTimeline paymentTerms={order?.tlData} />
                      )}
                      {order?.type == "Return with tracking" && (
                        <ReturnTrackingTimeline tracking={order?.tlData} />
                      )}
                    </>
                  )}
                </>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </>
      ) : (
        <>{check && <TimeLineComment comment={order} />}</>
      )}
    </>
  );
}
