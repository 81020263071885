import React, { useState } from "react";
import {
  Typography,
  Stack,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  Divider,
  FormGroup,
  Checkbox,
  Button,
} from "@material-ui/core";
import {
  AlternateEmailOutlined,
  SentimentSatisfiedOutlined,
  AttachFileOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  timelineItemClasses,
} from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import OrderTimeline from "./order/OrderTimeline";
import PropTypes from "prop-types";
import { insertComment } from "../../../redux/actions/TimelineAction";
import DiscountTimeline from "./discount/DiscountTimeline";
import CustomerTimeLine from "./customer/CustomerTimeLine";
import GiftCardTimeline from "./giftCard/GiftCardTimeline";
import TransferTimeline from "./transfer/TransferTimeline";
import { LoadingButton } from "@material-ui/lab";

OrderTimeline.propTypes = {
  timeLine: PropTypes.array,
  id: PropTypes.string,
  tlType: PropTypes.string,
  singleTransferData: PropTypes.object,
};

export default function TimeLine(props) {
  const { timeLine, id, tlType, singleTransferData, issuegift } = props;
  const dispatch = useDispatch();
  const store = useSelector((state) => state.auth.profilePayload);

  const [check, setCheck] = useState(true);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ margin: 20 }}
      >
        <h5 style={{ paddingTop: 10 }}>TimeLine</h5>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox defaultChecked onClick={() => setCheck(!check)} />
            }
            label={check ? "Show Comments" : "Hide Comments"}
          />
        </FormGroup>
      </Stack>
      <Divider />

      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          marginLeft: "-5px",
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              {store?.user?.init}
              {/* {store.name
                .split(/\s/)
                .reduce((response, word) => (response += word.slice(0, 1)), "")
                .toUpperCase()} */}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <FormControl
              variant="outlined"
              sx={{ marginTop: "12px", width:"100%" }}
            >
              {/* <InputLabel>Leave a comment...</InputLabel> */}
              <OutlinedInput
                style={{height:45}}
                placeholder="Leave a comment..."
                
                // type={values.showPassword ? 'text' : 'password'}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                // label="Leave a comment..."
                endAdornment={
                  <InputAdornment position="end">
                    {/* <IconButton>
                      <SentimentSatisfiedOutlined />
                    </IconButton> */}
                    {/* <IconButton>
                      <AlternateEmailOutlined />
                    </IconButton> */}
                    {/* <IconButton>
                      <AttachFileOutlined />
                    </IconButton> */}
                    <IconButton edge="end">
                      <LoadingButton
                        variant="contained"
                        size="small"
                        disabled={comment ? false : true}
                        loading={loading}
                        onClick={() =>(
                          dispatch(
                            insertComment(id, tlType, comment, setLoading)
                          ),setComment(""))
                        }
                      >
                        Post
                      </LoadingButton>
                    </IconButton>
                  </InputAdornment>
                }
                // label="Leave a comment..."
              />
              <Typography>
                <p
                  style={{
                    textAlign: "right",
                    fontSize: "14px",
                    color: "darkgray",
                    marginTop: "0px",
                  }}
                >
                  Only you and other staff can see comments
                </p>
              </Typography>
            </FormControl>
          </TimelineContent>
        </TimelineItem>
      </Timeline>

      {timeLine?.map((timeline, index) => {
        return (
          <>
            <p
              style={{
                marginLeft: "55px",
                fontSize: "14px",
                color: "grey",
              }}
            >
              {" "}
              {timeline?._id}
            </p>

            {/* {setLastId(timeline?.items[9]?._id)} */}

            {timeline?.items?.map((data) => {
              return (
                <>
                  {data?.tlType == "order" && (
                    <OrderTimeline order={data} check={check} />
                  )}
                  {data?.tlType == "customer" && (
                    <CustomerTimeLine customer={data} check={check} />
                  )}
                  {data?.tlType == "discount" && (
                    <DiscountTimeline discount={data} check={check} />
                  )}
                  {data?.tlType == "giftCard" && (
                    <GiftCardTimeline giftCard={data} check={check} />
                  )}
                  {data?.tlType == "transfer" && (
                    <TransferTimeline
                      transfer={data}
                      check={check}
                      singleTransferData={singleTransferData}
                    />
                  )}
                </>
              );
            })}
          </>
        );
      })}
    </>
  );
}
