import ReturnReducer from "./ReturnReducer";

const initialState = {
  orderPayload: [],
  FulfillPayload: [],
  exportorderPayload: [],
  transactionPayload: [],
  orderpaymentMethod:[],
  fullFillDetails:{}
  //  productTypePayload: []
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ORDER": {
      return {
        ...state,
        orderPayload: action.payload,
      };
    }

    case "GET_FULFILL_DETAILS": {
      return {
        ...state,
        fullFillDetails: action.payload,
      };
    }

    case "EXPORT_ORDER": {
      return {
        ...state,
        exportorderPayload: action.payload,
      };
    }

    case "GETALL_DRAFTORDER": {
      return {
        ...state,
        draftorderPayload: action.payload,
      };
    }

    case "GET_SINGLE_ORDER": {
      return {
        ...state,
        singleOrderPayload: action.payload,
      };
    }

    case "PAY_CALCULATION": {
      return {
        ...state,
        payCalculationPayload: action.payload,
      };
    }

    case "UPDATE_ORDER_NOTE": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          note: { ...state.singleOrderPayload.note, value: action.payload },
        },
      };
    }

    case "UPDATE_ORDER_TAGS": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          tags: action.payload,
        },
      };
    }

    case "UPDATE_SHIPPING_ADDRESS": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          billAdd: action.payload,
        },
      };
    }

    case "CANCEL_REASON": {
      return {
        ...state,
        orderCancelPayload: action.payload,
      };
    }

    case "PAYMENT_METHOD": {
      return {
        ...state,
        orderpaymentMethod: action.payload,
      };
    }

    case "ORDER_ARCHIVE": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          status: "Archived",
        },
      };
    }

    case "ORDER_UNARCHIVE": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          status: "Active",
        },
      };
    }

    case "MARK_AS_RETURN": {
      const returnd = state.singleOrderPayload.return.map((item) => {
        if (item._id == action.payload) {
          item.status = "Returned";
        }
        return item;
      });
      const data = returnd.filter((x) => x.status == "Return in Progress");

      if (data.length > 0) {
        return {
          ...state,
          singleOrderPayload: {
            ...state.singleOrderPayload,
            return: returnd,
            returnStatus: "Return in Progress",
          },
        };
      } else {
        return {
          ...state,
          singleOrderPayload: {
            ...state.singleOrderPayload,
            return: returnd,
            returnStatus: "Returned",
          },
        };
      }
    }

    case "CANCEL_RETURN": {
      var returnd = state.singleOrderPayload.return.find(
        (x) => x._id == action.payload
      );

      if (state.singleOrderPayload.fullfill.length > 0) {
        var map = state.singleOrderPayload.fullfill.map((val) => {
          let fQty = 0;
          const fid = returnd.items.find((o) => o.fid == val._id);
          if (fid) {
            var retitem = val.items.map((i) => {
              const item = returnd.items.find((o) => {
                if (o.vid) {
                  return o.vid == i.vid && o.pid == i.pid;
                } else {
                  return o.pid == i.pid;
                }
              });
              if (item) {
                fQty += item.qty;
                return {
                  ...i,
                  fQty: i.fQty + item.qty,
                  retQty: i.retQty - item.qty,
                };
              } else {
                return i;
              }
            });
            return {
              ...val,
              items: retitem,
              fQty: fQty,
            };
          } else {
            return val;
          }
        });
      }
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          fullfill: map,
          return: state.singleOrderPayload.return.filter(
            (x) => x._id != action.payload
          ),
          returnStatus : state.singleOrderPayload.return.filter(
            (x) => x._id != action.payload
          ).length > 0 ? "Return in progress" : "",
        },
      };
    }

    case "CANCEL_FULFILL": {
      var ful = state.singleOrderPayload.fullfill.find(
        (x) => x._id == action.payload
      );

      var map = state.singleOrderPayload.items.map((val) => {
        const item = ful.items.find((o) => {
          if (o.vid) {
            return o.vid == val.vid && o.pid == val.pid;
          } else {
            return o.pid == val.pid;
          }
        });
        if (item) {
          return {
            ...val,
            ufQty: val.ufQty + item.qty,
            fQty: val.fQty - item.qty,
            urQty : val.rQty - item.qty
          };
        } else {
          return val;
        }
      });

      var ufqty = map.reduce(function (total, item) {
        return total + item.ufQty;
      }, 0);

      var rQty = map.reduce(function (total, item) {
        return total - item.urQty;
      }, 0);

      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          ufQty: ufqty,
          rQty: rQty,
          items: map,
          fullfill: state.singleOrderPayload.fullfill.filter(
            (x) => x._id != action.payload
          ),
        },
      };
    }

    case "MARK_AS_PAID": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          paid: state.singleOrderPayload.total,
          payStatus: "Paid",
        },
      };
    }

    case "UPDATE_PAYMENT_METHOD": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          payMethod: state.orderpaymentMethod.find((o) => {return o._id == action.payload.payMethod} ),
          dueDate: action.payload.dueDate,
          status: "Active",
        },
      };
    }

    case "UPDATE_ORDER": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          _id: { ...state.singleOrderPayload._id, _id: action.payload },
        },
      };
    }

    case "REMOVE_CUSTOMER": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          customer: null,
        },
      };
    }

    case "TRACK_FULFILL": {
      const items = state.singleOrderPayload.fullfill.map((item) => {
        if (item._id == action.payload.fulfillId) {
          return (
            (item.trackingNo = action.payload.trackingNo),
            (item.carrier = action.payload.carrier),
            (item.trackingUrl = action.payload.trackingUrl)
          );
        } else {
          return item;
        }
      });

      // return {
      //   ...state,
      //   singleOrderPayload: items,
      // };
    }

    case "UPDATE_CUSTOMER": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          customer: action.payload,
        },
      };
    }

    case "CANCEL_ORDER": {
      return {
        ...state,
        singleOrderPayload: {
          ...state.singleOrderPayload,
          cancel: {
            id: action.payload.reasonId,
            status: "Canceled",
          },
          status: "Archived",
        },
      };
    }

    case "ORDER_TRANCTION": {
      return {
        ...state,
        transactionPayload: action.payload,
      };
    }

    default:
      return state;
  }
}
