import axios from "axios";
import { BASE_URL, FAKE_URL} from '../Provider/api';
import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// Get Profile
// export function getLegal(authToken) {
//     return async function (dispatch) {
//         dispatch({
//             type: "IS_LOADING",
//             payload: true,
//           });
//         const headers = { Authorization: 'Bearer ' + authToken };
//         //console.log(headers)
//         axios({
//             headers: headers,
//             method: 'post',
//             responseType: 'json',
//             url: BASE_URL + "/api/settings/get-legal"
//         })
//             .then(function (response) {
//               console.log('Get Profile Action=================', response.data);
//               dispatch({
//                 type: "IS_LOADING",
//                 payload: false,
//               });
//                 if (response.data.status === true) {
//                     dispatch({
//                         type: 'GET_LEGEL',
//                         payload: response.data.data
//                     })
//                 } else {
//                     dispatch({
//                         type: 'MESSAGE',
//                         payload: {
//                             type: "error",
//                             text: "Server connection failed. Ensure network connection"
//                         }
//                     })
//                 }
//             });
//     }
// }
export function getLegal(values) {
    return async function (dispatch) {
      dispatch({
        type: "IS_LOADING",
        payload: true,
      });
  
      ApiExt.post("api/settings/get-legal", values)
        .then(function (response) {
          dispatch({
            type: "IS_LOADING",
            payload: false,
          });
          if (response.data.status === true) {
            dispatch({
              type: "GET_LEGEL",
              payload: response?.data?.data,
            });
          } else {
            toast.warn(response?.data?.msg);
          }
        })
        .catch(function (error) {
          dispatch({
            type: "IS_LOADING",
            payload: false,
          });
          toast.error("Something went wrong. Please try again.");
        });
    };
  }
   