import ApiExt from "../Provider/ApiExt";
import { toast } from "react-toastify";

// Get All Vendor
export function getAllVendor(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("api/vendor/get-all", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_VENDOR",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Vendor
export function getSingleVendor(_id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("api/vendor/get", { id: _id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SINGLE_VENDOR",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Insert Vendor
export function insertVendor(values,callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      name: values.name,
      com: values.com,
      address: values.address,
      apertment: values.apertment,
      city: values.city,
      country: values?.country?._id,
      province: values.province?._id,
      pincode: values.pincode,
      phone: +values.phone,
      ext: values.ext?.dial_code,
      status: values.status,
    };
    ApiExt.post("/api/vendor/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success("Vendor Created Succesfully");
          callback(response.data?.id);
        } else {
          toast.warn("Can't enter same email and phone number..");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Vendor
export function updateVendor(vendorId, values, setLoading,callback) {
  return async function (dispatch) {
     setLoading(true);

    const value = {
      id: vendorId,
      data: {
        name: values.name,
        com: values.com,
        address: values.address,
        apertment: values.apertment,
        city: values.city,
        country: values?.country?._id,
        province: values.province?._id,
        pincode: values.pincode,
        phone: +values.phone,
        ext: values.ext?.dial_code,
        status: values.status,
      },
    };

    ApiExt.post("/api/vendor/update", value)
      .then(function (response) {
        setLoading(false);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_VENDOR",
            payload: value,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
         setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
}

// Delete Vendor
export function delVendor(vId,setDeleteLoading,callback) {
  return async function (dispatch) {
     setDeleteLoading(true);
    ApiExt.post("/api/vendor/delete", { id: vId })
      .then(function (response) {
         setDeleteLoading(false);
        if (response.data.status) {
          toast.success(response.data.msg);
          callback();
        }
      })
      .catch((error) => {
         setDeleteLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Archived Vendor
export function ArchiveVendor(vId, setArchiveLoading) {
  return async function (dispatch) {
     setArchiveLoading(true);
    ApiExt.post("/api/vendor/archived", { id: vId })
      .then(function (response) {
         setArchiveLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "ARCHIVED_VENDOR",
            payload: vId,
          });
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
         setArchiveLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// unArchive Vendor
export function UnArchiveVendor(vId, setArchiveLoading) {
  return async function (dispatch) {
     setArchiveLoading(true);
    ApiExt.post("/api/vendor/unarchived", { id: vId })
      .then(function (response) {
         setArchiveLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "UNARCHIVED_VENDOR",
            payload: vId,
          });
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
         setArchiveLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}
