import moment from "moment";
import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

export function getCollections(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("api/collection/get-all", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_COLLECTION",
            payload: response.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function getCollectionsSingle(_id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("api/collection/get", { collId: _id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_COLLECTION_SINGLE",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Create Collection
export function saveCollection(values, seo, media, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      title: values.title,
      desc: values.desc,
      type: values.type,
      // avail_date: moment().format('YYYY-MM-DD'),
      images: media?.map((item) => item._id) || [],
      theme: values.theme,
      seo: {
        title: seo.title || values?.title,
        desc: seo.desc || values?.desc,
        slug: seo.slug || values?.title,
      },
      status: values.status,
    };

    ApiExt.post("/api/collection/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}
// Update Collection
export function editCollection(id, values, seo, media, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      collId: id,
      data: {
        title: values.title,
        desc: values.desc,
        type: values.type,
        // avail_date: values.avail_date,
        images: media?.map((item) => item._id) || [],
        theme: values.theme,
        seo: {
          title: seo.title || values?.title,
          desc: seo.desc || values?.desc,
          slug: seo.slug || values?.title,
        },
        status: values.status,
      },
    };
    ApiExt.post("/api/collection/update", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success("Collection updated");
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Archived Collection
export function archiveCollection(id, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/collection/archive", { collId: id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "ARCHIVED_COLLECTION",
            payload: id,
          });
          toast.success("Collection Archived");
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Active Collection
export function unarchiveCollection(id, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/collection/unarchive", { collId: id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "UNARCHIVED_COLLECTION",
            payload: id,
          });
          toast.success("Collection unarchived");
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Delete Collection
export function deleteCollection(id, callback, setDeleteLoading) {
  return async function (dispatch) {
    setDeleteLoading(true);
    ApiExt.post("/api/collection/delete", { collId: id })
      .then(function (response) {
        setDeleteLoading(false);
        if (response.data.status === true) {
          dispatch({
            type: "DELETE_COLLECTION",
            payload: id,
          });
          toast.success("Collection deleted");
          callback();
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setDeleteLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Delete Collection Multiple
export const deleteCollectionMultiple = (_ids, lastId, setSelected) => {
  return async function (dispatch) {
    ApiExt.post("api/Collection/delete-multiple", { pid: _ids })
      .then(function (response) {
        if (response.data.status === true) {
          setSelected([]);
          dispatch(
            getCollections({
              next: lastId,
              limit: 10,
              search: {},
            })
          );
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
      });
  };
};

export function getCollectionType() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-Collection-type", {
      next: "",
      limit: 1000,
    })
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "Collection_TYPE",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
      });
  };
}

// getallcollection
export function getAllCollections(values) {
  return async function (dispatch) {
    ApiExt.post("/api/app/getAll-collections", values)
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_ALL_COLLECTION",
            payload: response.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong. Please try again.");
      });
  };
}
