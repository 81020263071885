const initialState = {
  allPagesPayload: [],
  pageEditorData: {},
  editorSchemaData: [],
  pageHeaderData: {},
  pageFooterData: {},
  pageEditorSection: "",
  pageSchemaData:{},
  settingsSchema: [],
  settingsData: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_PAGES": {
      return {
        ...state,
        allPagesPayload: action.payload,
      };
    }

    case "GET_SINGLE_PAGE": {
      return {
        ...state,
        singlePagePayload: action.payload,
      };
    }

    case "SET_EDITOR_SECTION": {
      return {
        ...state,
        pageEditorSection: action.payload,
      };
    }

    case "SET_EDITOR_PAGE_DATA": {
      return {
        ...state,
        pageEditorData: action.payload,
      };
    }

    case "SET_HEADER_PAGE_DATA": {
      return {
        ...state,
        pageHeaderData: action.payload,
      };
    }

    case "SET_FOOTER_PAGE_DATA": {
      return {
        ...state,
        pageFooterData: action.payload,
      };
    }

    case "SET_EDITOR_SCHEMA_DATA": {
      return {
        ...state,
        editorSchemaData: action.payload,
      };
    }

    case "SET_PAGE_SCHEMA_DATA": {
      return {
        ...state,
        pageSchemaData: action.payload,
      };
    }

    case "SET_SETTINGS_SCHEMA": {
      return {
        ...state,
        settingsSchema: action.payload,
      };
    }

    case "SET_SETTINGS_DATA": {
      return {
        ...state,
        settingsData: action.payload,
      };
    }

    case "ADD_EDITOR_SECTION": {
      const x = {
        ...state,
        pageEditorData: {
          ...state.pageEditorData,
          order: [...state.pageEditorData.order, action.payload.id],
          sections: {
            ...state.pageEditorData.sections,
            [action.payload.id]: action.payload.data
          }
        }
      };
      return x;
    }

    case "EDIT_EDITOR_SECTION": {
      return {
        ...state,
        pageEditorData: {
          ...state.pageEditorData,
          sections: {
            ...state.pageEditorData.sections,
            [action.payload.section]: {
              ...state.pageEditorData.sections[action.payload.section],
              settings: action.payload.data
            }
          }
        }
      };
    }

    case "EDIT_HEADER_SECTION": {
      return {
        ...state,
        pageHeaderData: {
          ...state.pageHeaderData,
          sections: {
            ...state.pageHeaderData.sections,
            [action.payload.section]: {
              ...state.pageHeaderData.sections[action.payload.section],
              settings: action.payload.data
            }
          }
        }
      };
    }

    case "EDIT_FOOTER_SECTION": {
      return {
        ...state,
        pageFooterData: {
          ...state.pageFooterData,
          sections: {
            ...state.pageFooterData.sections,
            [action.payload.section]: {
              ...state.pageFooterData.sections[action.payload.section],
              settings: action.payload.data
            }
          }
        }
      };
    }

    case "DELETE_EDITOR_SECTION": {
      const newState = { ...state.pageEditorData.sections };
      delete newState[action.payload.section];
      return {
        ...state,
        pageEditorData: {
          ...state.pageEditorData,
          sections: newState,
          order: state.pageEditorData.order.filter(x => x !== action.payload.section)
        }
      };
    }

    case "ADD_EDITOR_BLOCK": {
      return {
        ...state,
        pageEditorData: {
          ...state.pageEditorData,
          sections: {
            ...state.pageEditorData.sections,
            [action.payload.section]: {
              ...state.pageEditorData.sections[action.payload.section],
              blocks: {
                ...state.pageEditorData.sections[action.payload.section].blocks,
                [action.payload.id]: action.payload.data
              },
              block_order: [...state.pageEditorData.sections[action.payload.section].block_order, action.payload.id]
            }
          }
        }
      };
    }

    case "EDIT_EDITOR_BLOCK": {
      const a = {
        ...state,
        pageEditorData: {
          ...state.pageEditorData,
          sections: {
            ...state.pageEditorData.sections,
            [action.payload.section]: {
              ...state.pageEditorData.sections[action.payload.section],
              blocks: {
                ...state.pageEditorData.sections[action.payload.section].blocks,
                [action.payload.id]: {
                  ...state.pageEditorData.sections[action.payload.section].blocks[action.payload.id],
                  settings: action.payload.data
                }
              }
            }
          }
        }
      };
      return a;
    }

    case "DELETE_EDITOR_BLOCK": {
      const newState = { ...state.pageEditorData.sections[action.payload.section].blocks };
      delete newState[action.payload.block];
      return {
        ...state,
        pageEditorData: {
          ...state.pageEditorData,
          sections: {
            ...state.pageEditorData.sections,
            [action.payload.section]: {
              ...state.pageEditorData.sections[action.payload.section],
              blocks: newState,
              block_order: state.pageEditorData.sections[action.payload.section].block_order.filter(item => item !== action.payload.block)
            }
          }
        }
      };
    }

    case "ADD_HEADER_SECTION": {
      const x = {
        ...state,
        pageHeaderData: {
          ...state.pageHeaderData,
          order: [...state.pageHeaderData.order, action.payload.id],
          sections: {
            ...state.pageHeaderData.sections,
            [action.payload.id]: action.payload.data
          }
        }
      };
      return x;
    }

    case "EDIT_HEADER_SECTION": {
      return {
        ...state,
        pageHeaderData: {
          ...state.pageHeaderData,
          sections: {
            ...state.pageHeaderData.sections,
            [action.payload.name]: action.payload.data
          }
        }
      };
    }

    case "DELETE_HEADER_SECTION": {
      const newState = { ...state.pageHeaderData.sections };
      delete newState[action.payload.section];
      return {
        ...state,
        pageHeaderData: {
          ...state.pageHeaderData,
          sections: newState,
          order: state.pageHeaderData.order.filter(x => x !== action.payload.section)
        }
      };
    }

    case "ADD_FOOTER_SECTION": {
      const x = {
        ...state,
        pageFooterData: {
          ...state.pageFooterData,
          order: [...state.pageFooterData.order, action.payload.id],
          sections: {
            ...state.pageFooterData.sections,
            [action.payload.id]: action.payload.data
          }
        }
      };
      return x;
    }

    case "EDIT_FOOTER_SECTION": {
      return {
        ...state,
        pageFooterData: {
          ...state.pageFooterData,
          sections: {
            ...state.pageFooterData.sections,
            [action.payload.name]: action.payload.data
          }
        }
      };
    }

    case "DELETE_FOOTER_SECTION": {
      const newState = { ...state.pageFooterData.sections };
      delete newState[action.payload.section];
      return {
        ...state,
        pageFooterData: {
          ...state.pageFooterData,
          sections: newState,
          order: state.pageFooterData.order.filter(x => x !== action.payload.section)
        }
      };
    }

    default:
      return state;
  }
}