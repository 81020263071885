import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

export function getCountry() {
  return async function (dispatch) {
    ApiExt.post("api/app/get-country")
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_COUNTRY",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
      });
  };
}

export function getLocation() {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("api/location/get-all")
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_LOCATION",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
      });
  };
}

export function getSingleLocation(_id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("api/location/get", { locId: _id })
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "IS_LOADING",
            payload: false,
          });
          dispatch({
            type: "GET_SINGLE_LOCATION",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: true,
        });
      });
  };
}

// Create Location
export function saveLocation(values,setLoading,callback) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      name: values?.name,
      isOnline: values?.isOnline,
      isDefault: values?.isDefault,
      address: values?.address,
      apertment: values?.apertment,
      city: values?.city,
      country: values?.country?._id,
      province: values?.province?._id || "",
      pincode: values?.pincode.toString(),
      phone: +values?.phone.toString(),
      ext: values?.ext?.dial_code,
      status: values?.status,
    };
    ApiExt.post("/api/location/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback(response.data);
        } else {
          callback(response.data);
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
}

// Update Location
export function updateLocation(locId, values,setLoading,callback) {
  return async function (dispatch) {
   setLoading(true);

    const data = {
      locId: locId,
      data: {
        name: values?.name,
        isOnline: values?.isOnline,
        isDefault: values?.isDefault,
        address: values?.address,
        apertment: values?.apertment,
        city: values?.city,
        country: values?.country?._id || "",
        province: values?.province?._id || "",
        pincode: values?.pincode.toString(),
        phone: +values?.phone.toString(),
        ext: values?.ext?.dial_code,
        status: "active",
      },
    };
    ApiExt.post("/api/location/update", data)
      .then(function (response) {
       setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
       setLoading(false);
      });
  };
}

export function saveDefaultLocation(_id,setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("api/location/default", { locId: _id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          dispatch(getLocation());
          toast.success("Default Location Changed");
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch(function (error) {
        setLoading(false)
      });
  };
}

export function locationDelete(_id,setLoading) {
  return async function (dispatch) {
    setLoading(true)
    ApiExt.post("/api/location/activation", { locId: _id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          dispatch(getLocation());
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
}
