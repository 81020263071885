import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from 'react-color';
import {
    Select,
    Popover,
    Avatar,
    Typography,
    Stack
} from "@material-ui/core";


ColorPicker.propTypes = {
    /**
     * Callback fired when the Done is clicked.
     * 
     * @param {object} selectedItems Can be: `"object"`, `"array"`.
     */
    onChange: PropTypes.func,
    /**
    * defaultValue, usually the pre selected value.
    */
    defaultValue: PropTypes.string,
    /**
     * value, usually the pre selected value.
     */
    value: PropTypes.string,
    /**
     * label, usually the text value of search placeholder.
     */
    label: PropTypes.string
};

ColorPicker.defaultProps = {
    onChange: (color) => { },
    onClose: () => { },
    defaultValue: '#000',
    value: "#000",
    label: "Color"
};

export default function ColorPicker(props) {
    const { onChange, value, label, defaultValue } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (color)=>{
        onChange(color);
    }

    return (
        <>
            <Stack flexDirection={'row'} justifyContent={'left'} sx={{ padding: '5px' }}
                alignItems={'center'} onClick={handleClick}>
                <Avatar sx={{ bgcolor: value || defaultValue }}>&nbsp;</Avatar>
                <Stack sx={{ marginLeft: '10px' }}>
                    <Typography variant="subtitle2" gutterBottom>
                        {label}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                        {value || defaultValue}
                    </Typography>
                </Stack>
            </Stack>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <SketchPicker color={value || defaultValue} onChangeComplete={handleChange} />
            </Popover>
        </>
    );
}