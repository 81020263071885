import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// get timeline
export function getTimeLine(values) {
  return async function (dispatch) {

    ApiExt.post("/api/get-timeline",  values )
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "GET_TIMELINE",
            payload: response?.data?.result,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Insert comment
export function insertComment(id,tlType,comment,setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      iId: id,
      tlType: tlType,
      type: "comment",
      comment: comment,
    };

    ApiExt.post("/api/timeline-comment/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          toast.success("Comments inserted");
          dispatch({
            type: "INSERT_COMMENT",
            payload: data,
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// update comment
export function updateComment(_id, iId, type, comment, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      id: _id,
      iId: iId,
      tlType: type,
      comment: comment,
    };

    ApiExt.post("/api/timeline-comment/update", data)
      .then(function (response) {
        if (response.data.status === true) {
          setLoading(false);
          toast.success("Comments updated");
          dispatch({
            type: "UPDATE_COMMENT",
            payload: data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// delete comment
export const deleteComment = (id, iId, type, setLoading) => {
  return async function (dispatch) {
    setLoading(true);
    const data={
      id:id,
      iId:iId,
      tlType:type,
    }
    ApiExt.post("/api/timeline-comment/delete", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success("Comments deleted");
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
};
