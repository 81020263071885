import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from 'framer-motion';

import { withStyles } from '@material-ui/core/styles';
import { Box, Button, List, ListItem, Paper, Checkbox, Divider, LinearProgress } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { UploadMultiFile } from "../../../components/upload";
import { useDispatch, useSelector } from "react-redux";
import { varFadeInRight } from '../../animate';
import { getImages, uploadImageDirect } from 'src/redux/actions';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

ResourcePicker.propTypes = {
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    multiple: PropTypes.bool
};

ResourcePicker.defaultProps = {
    onSelect: () => { },
    onClose: () => { },
    multiple: false,
    history: true,
    preview: true
};

export default function ResourcePicker(props) {
    const { onSelect, onClose, multiple, history, preview } = props;
    const imageFile = useSelector((state) => state.image.imagePayload);

    const [isLoading, setIsLoading] = useState(true);
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [isLoadMore, setIsLoandMore] = useState(true);
    const [open, setOpen] = useState(true);
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsFirstTime(false);
        setIsLoading(true);
        if (history) {
            dispatch(getImages("", 40, {}));
        }
    }, []);

    useEffect(() => {
        setIsLoading(false);
        if (!isFirstTime) {
            setFiles(oldData => oldData.concat(imageFile));
            if (imageFile.length < 40) {
                setIsLoandMore(false);
            }
        }
    }, [imageFile]);

    const handleClose = () => {
        setOpen(false);
        onSelect();
        onClose();
    };

    const handleLoadmore = () => {
        setIsLoading(true);
        dispatch(getImages(files[files.length - 1]._id, 40, {}));
    }
    const handleSave = () => {
        setOpen(false);
        if (!multiple) {
            onSelect(files.find(o => o.selected));
        }
        else {
            onSelect(files.filter(o => o.selected));
        }
    };

    const handleChecked = (_id) => {

        const newState = files.map(obj => {
            // 👇️ if id equals _id, update selected property
            if (obj._id == _id) {
                return { ...obj, selected: !obj.selected };
            }

            if (!multiple) {
                // 👇️ otherwise return object as is
                return { ...obj, selected: false };
            }
            else {
                return obj;
            }
        });
        setFiles(newState);
    };


    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Select Image
                    {isLoading && <LinearProgress style={{ width: "100%" }} />}
                </DialogTitle>
                <DialogContent dividers>
                    <UploadMultiFile
                        showPreview
                        maxSize={3145728}
                        accept="image/*"
                        files={images}
                        onDrop={(e) => {
                            e.map((file) => {
                                setIsLoading(true);
                                dispatch(
                                    uploadImageDirect(file, file.name,
                                        //Success Callback
                                        (data) => {
                                            console.log('return data', data);
                                            setIsLoading(false);
                                            if (preview) {
                                                setFiles(oldFiles => [{
                                                    _id: data._id,
                                                    url: URL.createObjectURL(file),
                                                    selected: false
                                                }, ...oldFiles]);
                                            }
                                            else {
                                                setOpen(false);
                                                onSelect({
                                                    _id: data._id,
                                                    url: URL.createObjectURL(file),
                                                    selected: false
                                                });
                                            }
                                        },
                                        //Error Callback
                                        () => {
                                            setIsLoading(false);
                                        })
                                );
                            })
                        }}
                    />

                    <Divider sx={{ ...({ my: 3 }) }} />

                    <List disablePadding sx={{ ...({ my: 3 }) }}>
                        <AnimatePresence>
                            {files?.map((file) => {
                                // const { name, size, preview } = file;
                                // const key = isString(file) ? file : name;
                                const { ext, sid, _id, url, selected } = file
                                const key = _id;


                                return (
                                    <ListItem
                                        key={key}
                                        component={motion.div}
                                        {...varFadeInRight}
                                        sx={{
                                            p: 0,
                                            m: 0.5,
                                            width: 129,
                                            height: 129,
                                            borderRadius: 1.5,
                                            overflow: 'hidden',
                                            position: 'relative',
                                            display: 'inline-flex'
                                        }}
                                        onClick={() => handleChecked(_id)}
                                    >
                                        <Paper
                                            variant="outlined"
                                            component="img"
                                            // src={isString(file) ? file : preview}
                                            src={url}
                                            sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                                        />
                                        <Box sx={{ top: 6, left: 6, position: 'absolute', }}>
                                            <Checkbox
                                                checked={selected || false}
                                                onChange={() => handleChecked(_id)}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                sx={{ backgroundColor: '#ffffffc9' }}
                                            />
                                        </Box>
                                    </ListItem>
                                );



                            })}

                            {/* <ListItem
            component={motion.div}
            {...varFadeInRight}
            sx={{
              my: 1,
              py: 0.75,
              px: 2,
              borderRadius: 1,
              border: (theme) => `solid 1px ${theme.palette.divider}`,
              bgcolor: 'background.paper'
            }} /> */}
                        </AnimatePresence>
                    </List>

                    {files?.length > 0 && isLoadMore && !isLoading && <Button onClick={() => handleLoadmore()}>Load more</Button>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="outlined" disabled={!files?.find(o => o.selected)} >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}