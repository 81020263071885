import {
  Card,
  Divider,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import slugify from "react-slugify";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Launch } from "@material-ui/icons";

StoreSeo.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  seo: PropTypes.object,
  setSeo: PropTypes.func,
  blog: PropTypes.string,
  manageBlog: PropTypes.string,
  product: PropTypes.string,
  page: PropTypes.string,
  collection: PropTypes.string,
  giftcard: PropTypes.string,
};

export default function StoreSeo(props) {
  const {
    values,
    setFieldValue,
    seo,
    setSeo,
    blog,
    manageBlog,
    product,
    page,
    collection,
    giftcard,
  } = props;
  const store = useSelector((state) => state.auth.profilePayload);
  const [slug, setSlug] = useState();
  const [value, setValue] = useState(false);

  return (
    <Card sx={{ mt: 3, p: 3 }}>
      <Stack spacing={3}>
        <Stack>
          <Stack style={{flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
          <h4 style={{flex:1}}>Search engine listing preview</h4>
          <Link
            style={{
              cursor: "pointer",
              textAlign: "right",
            }}
            onClick={() => setValue(!value)}
          >
            Edit website SEO
          </Link>
          </Stack>
          <Stack style={{width:'100%'}}>
          {values?.title == "" && (
            <Typography sx={{ fontSize: "14px", marginTop: "10px"}}>
              Add a title to see how this {blog && "Blog"}
              {manageBlog && "Manage Blog"}
              {product && "Product"}
              {page && "Page"}
              {collection && "Collection"}
              {giftcard && "Gift Card"} post might appear in a search engine
              listing.
            </Typography>
          )}
          </Stack>
        </Stack>
        <Stack>
          <Typography sx={{ color: "blue", fontSize: "20px" }}>
            {seo?.title || values?.title}
          </Typography>

          <Stack direction={"row"} alignItems={"center"}>
            <Link
              style={{ cursor: "pointer" }}
              href={
                (blog &&
                  "https://" +
                    store.domain +
                    "/blog/" +
                    slugify(seo?.slug || values?.title)) ||
                (page &&
                  "https://" +
                    store.domain +
                    "/page/" +
                    slugify(seo?.slug || values?.title)) ||
                (manageBlog &&
                  "https://" +
                    store.domain +
                    "/manageBlog/" +
                    slugify(seo?.slug || values?.title)) ||
                (product &&
                  "https://" +
                    store.domain +
                    "/product/" +
                    slugify(seo?.slug || values?.title)) ||
                (collection &&
                  "https://" +
                    store.domain +
                    "/collection/" +
                    slugify(seo?.slug || values?.title)) ||
                (giftcard &&
                  "https://" +
                    store.domain +
                    "/giftcard/" +
                    slugify(seo?.slug || values?.title))
              }
              target="_blank"
            >
              https://{store.domain}/{blog && "blog"}
              {manageBlog && "manageblog"}
              {product && "product"}
              {page && "page"}
              {collection && "collection"}
              {giftcard && "giftcard"}/{slugify(seo?.slug || values?.title)}
              <Launch style={{ fontSize: "15px" }} />
            </Link>
          </Stack>

          <Typography
            sx={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{
              __html:
                seo?.desc?.replace(/<[^>]+>/g, "")?.substring(0, 160) ||
                values?.desc?.replace(/<[^>]+>/g, "")?.substring(0, 160),
            }}
          ></Typography>
        </Stack>
        {value && (
          <>
            <Divider />

            <Stack>
              <label>Page Title</label>
              <TextField
                fullWidth
                placeholder={values?.title}
                value={seo?.title}
                onChange={(e) => (
                  setFieldValue("seo.title", e.target.value),
                  setSeo({
                    ...seo,
                    title: e.target.value,
                  })
                )}
              />
            </Stack>
            <Stack>
              <label>Description</label>
              <TextField
                placeholder={
                  values?.desc
                    ? values?.desc?.replace(/<[^>]+>/g, "")?.substring(0, 160)
                    : values?.content
                        ?.replace(/<[^>]+>/g, "")
                        ?.substring(0, 160)
                }
                multiline
                rows={4}
                value={seo?.desc?.replace(/<[^>]+>/g, "")?.substring(0, 160)}
                onChange={(e) => (
                  setFieldValue("seo.desc", e.target.value),
                  setSeo({
                    ...seo,
                    desc: e.target.value,
                  })
                )}
              />
            </Stack>
            <Stack>
              <label>URL and handle</label>
              <TextField
                fullWidth
                value={slugify(seo?.slug || values?.title)}
                onChange={(e) => (
                  setFieldValue("seo.slug", slugify(e.target.value)),
                  setSeo({
                    ...seo,
                    slug: slugify(e.target.value),
                  }),
                  setSlug(e.target.value)
                )}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      https://{store.slug}.myplanetshopping.com/
                      {blog && "blog"}
                      {manageBlog && "manageblog"}
                      {product && "product"}
                      {page && "page"}
                      {collection && "collection"}
                      {giftcard && "giftcard"}/
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            {/* {seo?.seoslug && (
              <Stack direction="row" spacing={1}>
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <Typography style={{ marginTop: "8px" }}>
                  Create a URL redirect for <mark>{values?.seo?.slug}</mark> ;{""}{" "}
                  <mark>{slug}</mark>
                </Typography>
              </Stack>
            )} */}
          </>
        )}
      </Stack>
    </Card>
  );
}
