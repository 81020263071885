import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";

// material
import {
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import {
  Box,
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  AppBar,
  Tab,
  makeStyles,
  ButtonGroup,
  Button,
  Tabs,
} from "@material-ui/core";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { getOrders } from "src/redux/actions/OrderAction";
import { getLocation } from "src/redux/actions";
import { getAllTransfer } from "src/redux/actions/TransferAction";
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  // justifyContent: 'space-around',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  // width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TransferListToolbar.propTypes = {
  numSelected: PropTypes.number,
  onDelete: PropTypes.func,
  setSelectedTab: PropTypes.func,
  setText: PropTypes.func,
};

export default function TransferListToolbar(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { numSelected, onDelete, setSelectedTab, setText } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: "#FFF",
    },
    tabBar: {
      paddingLeft: 20,
      backgroundColor: "#FFF",
    },
    linearContainer: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();

  const isLight = theme.palette.mode === "light";

  const [statusTab, setStatusTab] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleTransfer = (props) => {
    dispatch(
      getAllTransfer({
        next: props.next,
        prev: props.prev,
        limit: props.limit,
        search: props.search,
      })
    );
  };

  const handleTextSearch = _.debounce((event) => {
    setText(event);
    handleTransfer({
      next: "",
      prev: "",
      limit: 10,
      search: {
        transferNo: event,
        status: statusTab,
      },
    });
  }, 500);

  const handleTabChange = (event, newValue) => {
    setStatusTab(newValue);
    setSelectedTab(newValue);
    handleTransfer({
      next: "",
      prev: "",
      limit: 10,
      search: {
        status:
          newValue == "Pending"
            ? "Pending"
            : newValue == "Partially received"
            ? "Partially received"
            : newValue == "Received"
            ? "Received"
            : "",
      },
    });
  };

  return (
    <>
      <TabContext value={statusTab}>
        <AppBar position="static" color="default">
          <Tabs
            onChange={handleTabChange}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="All" value="" />
            <Tab label="Pending" value="Pending" />
            <Tab label="Partial" value="Partially received" />
            <Tab label="Received" value="Received" />
          </Tabs>
        </AppBar>
      </TabContext>
      <RootStyle>
        <Grid container>
          {/* <Grid item xs={4}> */}
          <Grid item xs={12} md={12}>
            <SearchStyle
              fullWidth
              style={{ height: "45px" }}
              placeholder="Filter transfer..."
              value={searchText}
              onChange={(e) => (
                setSearchText(e.target.value), handleTextSearch(e.target.value)
              )}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <>
          {/* <Grid style={{ paddingLeft: 10 }}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined primary button group"
              >
                <Button style={{ height: "45px" }}>Status</Button>
                <Button style={{ height: "45px" }}>Origin</Button>
                <Button style={{ height: "45px" }}>Destination</Button>
                <Button style={{ height: "45px" }}>More filters</Button>
              </ButtonGroup>
            </Grid>
            <Grid style={{ paddingLeft: 10 }}>
              <Button variant="outlined" style={{ height: "45px" }}>Saved</Button>
              <Button variant="outlined" style={{ height: "45px",marginLeft:10 }}>Sort</Button>
            </Grid> */}
        </>
      </RootStyle>
    </>
  );
}
